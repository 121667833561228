@import "/front/lightbox";
@import "/front/footer";
@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Indie+Flower&display=swap');

.card-header{
    background-color: #3c6382;
    color: white;
    padding: 10px 15px;
}

.navbar{
    background-color: #3c6382;
    color: white;
}

.nav-link{
    color: white !important;
}

.card{
    border-radius: 0 0 10px 10px
}

.parallax{
    height: 50vh;
    background : no-repeat fixed center;
    background-size: cover;
}

.parallax-mobile{
    height: 30vh;
    background-size: cover;
}

.img-logo-home{
    opacity: .9;
    height: 90%;
    width: 100%;
    padding-top: 5%;
}

.shadow{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.20) !important;
}


.card  img{
    transition: transform .2s; /* Animation */
}
.card  img:hover {
    transform: scale(1.02);
}

.navbar-brand{
    font-family:'Indie Flower',cursive
}

.modal-content{
    border-radius: 0;
}

.modal-header{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    border-radius: 0;
    border: none;
    background-color: #3c6382;
    color: white;
    text-align: center;
}

.text-site{
    color: #3c6382;
}

.background-site{
    background-color: #3c6382;
}

